import React from 'react'
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const useStyles = makeStyles(theme => ({
	hero: {
		minHeight: "100vh",
		position: 'relative',
		backgroundColor: ({ full }) => full ? theme.palette.common.black : "#999"
	},
	customHero: {
		height: '100vh',
		overflow: 'hidden',
		backgroundColor: "#999",

		[theme.breakpoints.down('sm')]: {
			height: ({ half }) => half ? '50vh' : "100vh",
		},

		'& div': {
			margin: '0 auto',
			maxWidth: 1080,

			[theme.breakpoints.down('sm')]: {
				maxWidth: 600,
				minHeight: ({ half }) => half ? '50vh' : "100vh",
			}
		},

		'& img': {
			[theme.breakpoints.down('sm')]: {
				width: "80%",
				margin: '0 auto',
			}
		},

		'& h1': {
			[theme.breakpoints.down('sm')]: {
				height: '50%',
			}
		}
	},
	title: {
		top: 0,
		color: theme.palette.common.white,
		width: "100%",
		height: "100%",
		opacity: 0.16,
		padding: theme.spacing(2),
		display: "flex",
		position: 'absolute',
		fontSize: `${theme.spacing(25)}px`,
		alignItems: "center",
		textAlign: 'center',
		textShadow: `1px 1px 10px ${theme.palette.common.black}`,
		textTransform: 'uppercase',
		justifyContent: "center",
		[theme.breakpoints.down('lg')]: {
			fontSize: `${theme.spacing(17)}px`,
		},
		[theme.breakpoints.down('md')]: {
			fontSize: `${theme.spacing(13)}px`,
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
			fontSize: `${theme.spacing(6)}px`,
		},
	},
	imageContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		minHeight: "100vh",
	},
	image: {
		opacity: 0.8,
		width: "100%",
		maxWidth: ({ full }) => full ? "unset" : 960,
		minHeight: "100vh",
		"& img": {
			objectFit: ({ full }) => full ? "cover" : "contain !important"
		}
	},
	placeholder: {
		minHeight: "100vh",
	}
}))

const Hero = ({ alt, image, title, full, half }) => {
	const classes = useStyles({ full, half })


	if (full) {
		return (
			<section className={classes.hero}>
				{image
					? <GatsbyImage image={getImage(image)} className={classes.image} alt={alt} />
					: <Box className={classes.placeholder} />
				}
				{title && <Typography variant="h1" className={classes.title}>{title}</Typography>}
			</section>
		)
	}

	if (half) {
		return (
			<section className={classes.customHero}>
				{image
					? <GatsbyImage image={getImage(image)} className={classes.image} alt={alt} />
					: <Box className={classes.placeholder} />
				}
				{title && <Typography variant="h1" className={classes.title}>{title}</Typography>}
			</section>
		)
	}

	return (
		<section className={classes.hero}>
			{image ? (
				<Box className={classes.imageContainer}>
					<GatsbyImage image={getImage(image)} className={classes.image} alt={alt} />
				</Box>
			) : <Box className={classes.placeholder} />
			}
			{title && <Typography variant="h1" className={classes.title}>{title}</Typography>}
		</section>
	)
}

Hero.defaultProps = {
	alt: "Hero image",
	full: false,
	half: false,
}

export default Hero
