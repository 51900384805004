import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Title from "../../components/title"
import Hero from "../../components/hero"

const useStyles = makeStyles(theme => ({
	lacquerDressageSaddles: {
		maxHeight: theme.spacing(80),
		overflow: 'hidden',
		overflowY: 'auto',
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			marginBottom: '20px',
			maxWidth: 350,
			minWidth: "51%",
			maxHeight: theme.spacing(50),
		},

	},

	wrapper: {
		'& h2': {
			[theme.breakpoints.down('sm')]: {
				fontSize: '2.5rem',
			}
		}
	},

	heroTitle: {
		'& section > h1': {
			[theme.breakpoints.down('lg')]: {
				fontSize: '90px',
			},
			'@media(max-width: 868px)': {
				fontSize: '70px ',
			},
			'@media(max-width: 687px)': {
				fontSize: '50px ',
			},
			'@media(max-width: 480px)': {
				fontSize: '33px ',
			},
		},
	},

	title: {
		marginBottom: theme.spacing(6)
	},
	lacquer: {
		backgroundColor: theme.palette.secondary.main
	},
	textAlignCenter: {
		textAlign: "center"
	},
	leatherType: {
		marginTop: theme.spacing(3)
	},
	leatherColor: {
		color: theme.palette.text.secondary,
	},
	beltLoopsColor: {
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(3)
	},
	beltLoopsSlogan: {
		textAlign: "center",
		marginTop: theme.spacing(4)
	},
	engravingContent: {
		backgroundColor: theme.palette.secondary.main,
		padding: theme.spacing(4),
		"& p": {
			margin: 0
		}
	},
	lacquerJumpingSaddlesGrid: {
		display: 'flex',
		flexFlow: "row nowrap",
		overflow: 'hidden',
		overflowX: 'auto',
		boxSizing: "border-box",
		scrollSnapType: "mandatory",
		scrollSnapType: "x mandatory",
	},
	lacquerJumpingSaddlesItem: {
		flex: `1 0 ${theme.spacing(25)}px`,
		margin: `0 ${theme.spacing(1)}px`,
		scrollSnapAlign: "start",
		[theme.breakpoints.down('sm')]: {
			flexBasis: "60vw",
		}
	},
	rest: {
		marginTop: theme.spacing(10),
		marginBottom: theme.spacing(10),
		[theme.breakpoints.down('sm')]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
		}
	}
}))

const CustomizationView = () => {
	const classes = useStyles()

	const { datoCmsPageCustomization } = useStaticQuery(graphql`
		query CustomizationViewQuery {
      datoCmsPageCustomization {
        heroTitle
        heroImage {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        leatherTitle
        leatherColor {
          id
          leatherType
          leatherColor
          leatherImage {
            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
        lacquerTitle
        lacquerImage {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        lacquerDressageSaddles {
          lacquerSaddleImage {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          }
          id
        }
        lacquerJumpingSaddles {
          lacquerSaddleImage {
            gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          }
          id
        }
        beltLoopsTitle
        beltLoopsSlogan
        beltLoops {
          id
          beltLoopColor
          beltLoopImage {
            gatsbyImageData(placeholder: TRACED_SVG, layout: CONSTRAINED)
          }
        }
        engravingBadgeTitle
        engravingBadgeDescription
        engravingBadgeImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
        restTitle
        restDescription
      }
    }
	`)

	const {
		heroTitle,
		heroImage,
		leatherTitle,
		leatherColor = [],
		lacquerTitle,
		lacquerImage,
		lacquerDressageSaddles = [],
		lacquerJumpingSaddles = [],
		beltLoopsTitle,
		beltLoops = [],
		beltLoopsSlogan,
		engravingBadgeImage,
		engravingBadgeTitle,
		engravingBadgeDescription,
		restTitle,
		restDescription,
	} = datoCmsPageCustomization || {}

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.heroTitle}>
				<Hero full image={heroImage} title={heroTitle} />
			</Box>
			<Container maxWidth="lg">
				<Box component="section" mt={10} mb={6}>
					<Title className={classes.title}>{leatherTitle}</Title>
					<Grid container spacing={3}>
						{leatherColor.map(({ id, leatherType, leatherColor: color, leatherImage }) => (
							<Grid item md={3} sm={6} xs={12} key={id} className={classes.textAlignCenter}>
								<GatsbyImage image={getImage(leatherImage)} alt={`${leatherType} ${color}`} />
								<Typography gutterBottom className={classes.leatherType}>
									<strong>
										{leatherType}
									</strong>
								</Typography>
								<Typography variant="body2" gutterBottom className={classes.leatherColor}>{color}</Typography>
							</Grid>
						))}
					</Grid>
				</Box>
			</Container>

			<Box component="section" mt={10} py={10} className={classes.lacquer}>
				<Container maxWidth="lg">
					<Title>{lacquerTitle}</Title>
					<Box py={4} display="flex" justifyContent="center">
						<GatsbyImage image={getImage(lacquerImage)} alt={lacquerTitle} />
					</Box>

					<Box my={10}>
						<Title className={classes.title}>Siodła ujeżdżeniowe</Title>
						<Grid container spacing={3} justifyContent='center'>
							{lacquerDressageSaddles.map(({ id, lacquerSaddleImage }, index) => (
								<Grid item md={4} sm={12} key={id} className={classes.lacquerDressageSaddles}>
									<GatsbyImage image={getImage(lacquerSaddleImage)} alt={`Siodła ujeżdżeniowe ${index}`} />
								</Grid>
							))}
						</Grid>
					</Box>

					<Box mt={10}>
						<Title className={classes.title}>Siodła skokowe</Title>
						<Grid container className={classes.lacquerJumpingSaddlesGrid}>
							{lacquerJumpingSaddles.map(({ id, lacquerSaddleImage }, index) => (
								<Grid item key={id} className={classes.lacquerJumpingSaddlesItem}>
									<GatsbyImage image={getImage(lacquerSaddleImage)} alt={`Siodła ujeżdżeniowe ${index}`} />
								</Grid>
							))}
						</Grid>
					</Box>
				</Container>
			</Box>

			<Container maxWidth="lg">
				<Box py={10}>
					<Title className={classes.title}>{beltLoopsTitle}</Title>
					<Grid container spacing={3}>
						{beltLoops.map(({ id, beltLoopColor, beltLoopImage }, index) => (
							<Grid item sm={4} xs={12} key={id} className={classes.textAlignCenter}>
								<GatsbyImage image={getImage(beltLoopImage)} alt={`${beltLoopsTitle} ${index}`} />
								<Typography className={classes.beltLoopsColor}>{beltLoopColor}</Typography>
							</Grid>
						))}
					</Grid>
					<Typography className={classes.beltLoopsSlogan}>{beltLoopsSlogan}</Typography>
				</Box>

				<Grid container>
					<Grid item sm={6} xs={12}>
						<GatsbyImage image={getImage(engravingBadgeImage)} alt={engravingBadgeTitle} />
					</Grid>
					<Grid item sm={6} xs={12} className={classes.engravingContent}>
						<Title>{engravingBadgeTitle}</Title>
						<Typography component="div" dangerouslySetInnerHTML={{ __html: engravingBadgeDescription }} />
					</Grid>
				</Grid>

				<Box className={classes.rest}>
					<Title className={classes.title}>{restTitle}</Title>
					<Typography component="div" dangerouslySetInnerHTML={{ __html: restDescription }} />
				</Box>
			</Container>
		</Box>
	)
}

export default CustomizationView;