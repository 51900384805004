import React from 'react';
import Layout from '../../components/layout';
import CustomizationView from '../../views/customizationView'

const CustomizationPage = () => (
	<Layout>
		<CustomizationView />
	</Layout>
)

export default CustomizationPage;